@media (max-width: 1399px) {
	.admin-section {
		--sidebar-width: 249px;
	}
	.sidebar-menu > ul > li > a > .icon {
		width: 18px;
		margin: 0 8px 0 0;
	}
	.sidebar-menu > ul > li {
		margin-bottom: 8px;
	}
	.sidebar-menu > ul > li > a {
		padding: 0 20px;
		height: 40px;
		font-size: 14px;
		border-right-width: 3px;
	}
	.sidebar-logo {
		width: 110px;
	}
	.sidebar-title {
		padding: 21px 21px 26px;
	}

	.admin-main-header h3 {
		font-size: 20px;
	}
	.a-menu > li > a {
		height: 36px;
		width: 36px;
		border-radius: 4px;
		padding: 8px;
	}
	.header-user .image {
		height: 43px;
		width: 43px;
		margin-right: 9px;
	}
	.header-user {
		margin-left: 12px;
		font-size: 15px;
	}
	.admin-main {
		--inner-gap: 20px;
		padding: 25px;
	}
	.statistic-single {
		border-radius: 4px;
		padding: 15px;
	}
	.statistic-single .icon {
		width: 36px;
		margin: 0 13px 0 0;
	}
	.statistic-single .content h3 {
		font-size: 13px;
		margin: 0 0 2px;
	}
	.statistic-single .content p {
		font-size: 10px;
	}

	.analytics-box .title-row h4 {
		font-size: 14.5px;
	}
	.analytics-box {
		border-radius: 4px;
		padding: 18px;
	}
	.box-3-menu {
		height: 30px;
		width: 30px;
		border-radius: 4px;
	}
	.analytics-box .title-row {
		margin: 0 0 17px;
	}

	.analytics-pai .analytics-pai-placeholder {
		padding: 0 12%;
	}
	.an-pi-label {
		font-size: 12.8px;
	}
	.an-pi-label .c-box {
		height: 11.7px;
		width: 11.7px;
		margin-right: 7px;
		border-radius: 3.5px;
	}

	.s-card-header h4 {
		font-size: 16px;
	}
	.s-card-header {
		padding: 19px;
	}
	.s-card-header .a-btn {
		font-size: 13.3px;
	}
	.s-card-row1 .text-side .h3 {
		font-size: 18px;
		margin: 0 0 10px;
	}
	.location-text {
		font-size: 11.2px;
	}
	.s-card-row1 .image {
		width: 210px;
		margin: 0 17px 0 0;
	}
	.sc-state-single h4 {
		font-size: 15.16px;
		margin: 0 0 3px;
	}
	.sc-state-single p {
		font-size: 10.84px;
	}
	.s-card-states-wrap {
		margin: 30px 0 0;
	}
	.s-card .bt-button-row {
		margin-top: 14px;
	}
	.default-button {
		font-size: 13px;
		padding: 18px 20px;
		border-radius: 4px;
	}
	.s-card-fill-row h4 {
		font-size: 13px;
		margin: 0 0 10px;
	}
	.s-card-fill-row {
		margin: 25px 0 0;
	}

	.table-box-title h4 {
		font-size: 16px;
	}
	.table-box-title {
		padding: 16px 18px;
	}
	.table-model thead tr th {
		font-size: 13px;
	}
	.table-model td {
		font-size: 14px;
		padding: 17px 7px;
	}
	.table-box-footer .row-dr .a-btn {
		font-size: 13.5px;
	}
	.t-search .input {
		width: 200px;
		padding: 7px 12px;
		font-size: 11px;
	}

	.drop-select > ul > li > a {
		padding: 14px 15px;
		font-size: 13px;
	}
	.notifications-dropdown {
		width: 342px;
	}

	.pagination {
		margin-top: 0px;
		padding: 20px 20px;
		font-size: 14px;
	}

	.checkbox-model {
		height: 15px;
		width: 15px;
		border-radius: 3px;
		font-size: 10px;
	}
	.t-str2-label .icon {
		width: 14px;
		margin: 0 7px 0 0;
		display: block;
	}

	.table-mdl-str3 .in-arrow img {
		width: 19px;
	}
	.th-dropdown > a .chev-down {
		font-size: 11px;
		margin: 0 0 0 7px;
	}
	.drop-select > ul > li > a > .p-icon {
		width: 19px;
	}
	.dropdown-sss {
		min-width: 90px;
	}
	.td-cheap {
		font-size: 12px;
		padding: 7px 8px;
		min-width: 66px;
		border-radius: 3px;
	}
	.table-mdl-str3 td {
		padding-top: 11px;
		padding-bottom: 11px;
	}

	.acc-grp-form-row {
		grid-column-gap: 40px;
		grid-row-gap: 20px;
	}

	.input-x-outer .i-check {
		font-size: 16px;
		right: 12px;
	}
	.pan-card-dyn {
		height: 148px;
		width: 296px;
		padding: 7px 13px;
		font-size: 9.3px;
	}
}

@media (max-width: 1199px) {
	.admin-main-header-outer {
		height: 74px;
	}
	.admin-main-header {
		padding: 16px 20px;
		background: #ffffff;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
		z-index: 100;
		position: relative;
		position: fixed;
		width: 100%;
	}
	.admin-sidebar-mobile .admin-sidebar-inner {
		height: calc(100vh - 115px);
	}
	.admin-sidebar-mobile {
		padding-top: 40px;
		width: 249px !important;
		z-index: 90;
		right: 0;
		left: unset;
		top: 75px;
		transform: translate(100%, 0);
		transition: 0.3s;
	}
	.offcanvas-opened .admin-sidebar-mobile {
		transform: translate(0%, 0);
	}
	.public-sidebar-mobile .admin-sidebar-inner {
		height: calc(100vh - 115px);
	}
	.public-sidebar-mobile {
		padding-top: 40px;
		width: 249px !important;
		z-index: 90;
		right: 0;
		left: unset;
		top: 59px;
		transform: translate(100%, 0);
		transition: 0.3s;
	}
	.offcanvas-opened .public-sidebar-mobile {
		transform: translate(0%, 0);
	}
	.admin-section {
		--sidebar-width: 0;
	}
	.admin-main {
		padding: 0;
	}
	.admin-main-inner {
		padding: 0 20px 20px;
	}
	.page-title-mobile {
		padding: 20px 0;
	}
	.page-title-mobile h3 {
		font-size: 22px;
		font-weight: 500;
		color: var(--theme__color1);
	}
	.header-user .txt {
		display: none;
	}

	.mobile-menubar {
		padding: 10px 15px;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 80;
		background: #ffffff;
		box-shadow: 0px 0px 10px 0px #CCC;
		border-radius: 14px 14px 0px 0px;
	}

	.mobile-menubar-menu > ul {
		display: flex;
		justify-content: space-evenly;
		text-align: center;
	}
	.mobile-menubar-menu > ul > li > a > .icon > svg > [stroke='#A9A9A9'] {
		transition: 0.3s;
	}

	.mobile-menubar-menu > ul > li > a > .menu-item-title {
		font-size: 10px;
		font-weight: 600;
		color: var(--theme__color3);
	}

	.mobile-menubar-menu
		> ul
		> li
		> a.active
		> .icon
		> svg
		> [stroke='#A9A9A9'],
	.mobile-menubar-menu
		> ul
		> li
		> a:hover
		> .icon
		> svg
		> [stroke='#A9A9A9'] {
		stroke: var(--theme__color1);
	}

	.mobile-menubar-menu > ul > li > a:hover > .menu-item-title {
		color: var(--theme__color1);
	}

	.mobile-menubar-menu > ul > li > a.active > .menu-item-title {
		color: var(--theme__color1);
	}

	.admin-main {
		padding-bottom: 120px;
	}
}

@media (max-width: 991px) {
	.s-card-row1 {
		flex-wrap: wrap;
	}
	.s-card-row1 .image {
		width: 100%;
		margin: 0 0px 14px 0;
	}

	.s-card-row-full .text-side h3 {
		font-size: 25px;
		margin-bottom: 13px;
	}
	.s-card-row-full .image-side .bt-button {
		margin-top: 20px;
	}
	.s-card-row-full .text-side .bt-p {
		font-size: 13px;
	}
}

@media (max-width: 768px) {
	.statistics-wrap {
		--item: 2;
		--inner-space: 15px;
		margin-bottom: 10px;
	}

	.analytics-box.analytics-activity {
		width: 100%;
	}

	.s-card-row {
		--item: 1;
	}

	.s-card-in-content {
		padding: 20px;
	}

	.pagi-nav li.corner {
		display: none;
	}

	.t-str2-label .icon {
		width: 13px;
		margin: 0 5px 0 0;
	}
	.td-bookmark {
		display: flex;
		width: 13px;
	}

	.s-card-row-full {
		flex-wrap: wrap;
	}
	.s-card-row-full .image-side {
		width: 100%;
		margin-left: 0%;
		order: -1;
		margin-bottom: 12px;
	}
	.s-card-row-full .text-side {
		width: 100%;
	}

	.acc-grp-form-row {
		grid-template-columns: repeat(1, 1fr);
	}

	.account-box {
		padding: 0;
		background: transparent;
		border-radius: 0;
	}
	.account-box.account-box-contact {
		padding-bottom: 35px;
		background: #fff;
		border-radius: 5px;
	}
	.account-c-group {
		background: #fff;
		border-radius: 5px;
		margin-bottom: 15px;
	}
	.acc-grp-title h3 {
		font-size: 16px;
	}
	.acc-grp-title {
		padding: 25px 22px;
		margin: 0 0 0;
		border-bottom: none;
	}
	.acc-grp-m-content {
		padding: 0 23px;
	}
	.acc-grp-m-content-inner {
		padding: 23px 0;
		border-top: 1px solid #e9e9e9;
	}
	.acc-grp-title .f-icon {
		display: block;
		margin-right: 8px;
	}
	.acc-grp-title .ind-icon {
		display: block;
		margin-left: auto;
		transition: 0.3s;
	}
	.acc-grp-title:not(.collapsed) .ind-icon {
		transform: rotate(180deg);
	}

	.acc-grp-form-row .column-dbl-column {
		grid-column: span 1;
	}
}

@media (max-width: 575px) {
	.mobile-menubar-menu > ul {
		justify-content: space-between;
	}

	.notifications-dropdown {
		width: 100vw;
		padding: 17px 20px;
	}
	.notifications-dropdown-inner {
		width: 342px;
		max-width: 100%;
		margin: 0 auto;
	}
	.s-card-row-full .text-side h3 {
		font-size: 17px;
		margin-bottom: 11px;
	}
	.s-card-row-full .s-card-states-wrap {
		padding-right: 0;
		margin-top: 18px;
	}
	.s-card-row-full .image-side .img-m img {
		border-radius: 6px;
	}
	.s-card-row-full .image-side {
		margin-bottom: 9px;
	}
}

@media (max-width: 370px) {
	.admin-main-inner {
		padding: 0 12px 20px;
	}
	.mbl-table {
		--se-padding: 12px;
	}
	.table-box-title {
		padding: 16px 12px;
	}
	.statistics-wrap {
		--item: 2;
		--inner-space: 12px;
		margin-bottom: 7px;
	}

	.admin-main {
		--inner-gap: 15px;
	}
	.statistic-single {
		padding: 12px;
	}
	.analytics-box {
		padding: 12px;
	}
	.s-card-header {
		padding: 15px 12px;
	}
	.s-card-in-content {
		padding: 15px 12px;
	}
}
