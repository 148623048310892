/*-----------------------------------=========Main-Style-Sheet=========----------------------------------------*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root {
  --theme__color1: #e83e8c;
  --theme__color2: #142c4b;
  --theme__color3: #114486;
  --poppins: "Poppins", sans-serif;
  --roboto: "Roboto", sans-serif;
}
*,
::before,
::after {
  box-sizing: border-box;
}
body {
  font-size: 16px;
  line-height: 1.2;
  background-color: #fff;
  color: #4d505f;
  overflow-x: hidden;
  word-wrap: break-word;
  word-break: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-family: var(--poppins);
}
a,
a:active,
a:focus,
a:active,
a:hover {
  text-decoration: none !important;
  color: inherit;
}
input {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}
::placeholder {
  opacity: 1;
}
:focus::placeholder {
  opacity: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed; /* Prevents HTML tables from becoming too wide */
  width: 100%;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
iframe {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin-bottom: 15px;
}
p:last-child {
  margin: 0;
}
.form-control:focus {
  outline: none !important;
}
*:focus {
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.main-wrap {
  width: 100%;
  overflow: hidden;
}
@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

/* --preloader-- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--theme__color1);
  border-top-color: #e2eefd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}
@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* --preloader-end-- */

/*=========admin-section========= */
.admin-section {
  --sidebar-width: 281px;
}
.admin-section-inner {
  display: flex;
  overflow: auto;
}
.admin-sidebar {
  width: var(--sidebar-width);
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
}
.admin-sidebar-inner {
  height: 100vh;
  overflow: auto;
}
.admin-main {
  --inner-gap: 30px;
  margin-left: var(--sidebar-width);
  width: 0;
  flex-grow: 1;
  padding: 33px;
  background: #f8f9fa;
  min-height: 100vh;
}
/*=========admin-section=========end */

/* ---admin-sidebar--- */
.admin-sidebar {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
}
.admin-sidebar-inner {
  display: flex;
  flex-direction: column;
}
.sidebar-title {
  padding: 26px 28px 31px;
}
.sidebar-logo {
  width: 155px;
  display: flex;
}
.sidebar-logo img {
}

.sidebar-menu {
}
.sidebar-menu > ul {
}
.sidebar-menu > ul > li {
  margin-bottom: 12px;
}
.sidebar-menu > ul > li:last-child {
  margin-bottom: 0;
}
.sidebar-menu > ul > li > a {
  padding: 0 28px;
  height: 48px;
  font-size: 15px;
  display: flex;
  border-right: 4px solid transparent;
  transition: 0.3s;
  align-items: center;
  color: #a9a9a9;
}
.sidebar-menu > ul > li > a:hover,
.sidebar-menu > ul > li > a.active {
  border-right-color: var(--theme__color1);
  color: var(--theme__color1);
  font-weight: 600;
}
.sidebar-menu > ul > li > a > .icon {
  width: 22px;
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-menu > ul > li > a > .icon svg [stroke="#A9A9A9"] {
  transition: 0.3s;
}
.sidebar-menu > ul > li > a:hover > .icon svg [stroke="#A9A9A9"],
.sidebar-menu > ul > li > a.active > .icon svg [stroke="#A9A9A9"] {
  stroke: var(--theme__color1);
}
/* ---admin-sidebar---end */

/* ---admin-main-header--- */
.admin-main-header {
  padding: 5px 0 5px;
  display: flex;
  justify-content: space-between;
}
.admin-main-header h3 {
  font-size: 27px;
  font-weight: 500;
  color: var(--theme__color1);
}
.admin-main-header .header-right {
  display: flex;
  align-items: center;
}
.a-menu {
  display: flex;
}
.a-menu > li {
  margin-right: 12px;
}
.a-menu > li:last-child {
  margin-right: 0;
}
.a-menu > li > a {
  height: 44px;
  width: 44px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--theme__color1);
  transition: 0.3s;
}
.a-menu > li > a:hover {
  background: var(--theme__color3);
}
.a-menu > li > a.show {
  background: #fff;
}
.a-menu > li > a > svg [stroke="white"] {
  transition: 0.3s;
}
.a-menu > li > a.show > svg [stroke="white"] {
  stroke: var(--theme__color1) !important;
}

.header-user {
  margin-left: 19px;
  font-size: 17px;
  font-weight: 500;
  color: #2e2e2e;
  display: flex;
  align-items: center;
}
.header-user .image {
  height: 52px;
  width: 52px;
  margin-right: 10px;
}
.header-user .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.offcanvas-open {
  margin-left: 7px;
  height: 24px;
  width: 24px;
  display: block;
  background: url("../icon/bars.svg");
}
.offcanvas-opened .offcanvas-open {
  background: url("../icon/x-close.svg");
  pointer-events: none;
}
/* ---admin-main-header---end */

/* ---statistics-wrap--- */
.statistics-wrap {
  --inner-space: var(--inner-gap);
  --bt-space: var(--inner-space);
  --item: 4;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--inner-space) * -0.5) calc(var(--bt-space) * -1);
  justify-content: center;
  margin-bottom: 1px;
}
.statistic-single {
  width: calc((100% / var(--item)) - var(--inner-space));
  margin: 0 calc(var(--inner-space) * 0.5) var(--bt-space);
  background: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 28px;
}
.statistic-single .icon {
  width: 56px;
  margin: 0 20px 0 0;
}
.statistic-single .content h3 {
  font-size: 20px;
  margin: 0 0 0;
  font-weight: 600;
  color: var(--theme__color2);
}
.statistic-single.pink-theme .content h3 {
  color: var(--theme__color1);
}
.statistic-single .content p {
  font-size: 15px;
  color: #4d505f;
  font-weight: 600;
}
.statistic-single:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* ---statistics-wrap---end */

/* ---analytics-row--- */
.analytics-row {
  --inner-space: var(--inner-gap);
  --bt-space: var(--inner-space);
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--inner-space) * -0.5) calc(var(--bt-space) * -1);
  margin-bottom: 1px;
}
.analytics-box {
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  padding: 24px;
  margin: 0 calc(var(--inner-space) * 0.5) var(--bt-space);
}
.analytics-box .title-row {
  margin: 0 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytics-box .title-row h4 {
  font-size: 16.5px;
  color: #4d505f;
}
.box-3-menu {
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f2f2f2;
  border-radius: 5px;
}
.analytics-box.analytics-activity {
  width: 60%;
}
.analytics-box.analytics-transactions {
  width: 0;
  flex-grow: 1;
}
.analytics-activity-graph {
  display: flex;
  justify-content: center;
}
.analytics-activity-graph .placeholder-graph {
  width: 100%;
}
.analytics-pai {
  display: flex;
  justify-content: center;
}
.analytics-pai .analytics-pai-placeholder {
  width: 100%;
  padding: 0 12%;
}
.analytics-pai-label-wrap {
  margin: 23px 0 10px;
  display: flex;
  justify-content: space-evenly;
}
.an-pi-label {
  font-size: 14.6px;
  font-weight: 400;
  color: #030229;
  display: flex;
  align-items: center;
}
.an-pi-label .c-box {
  height: 13.7px;
  width: 13.7px;
  margin-right: 8px;
  background: var(--theme__color2);
  border-radius: 4.5px;
  display: flex;
}
.an-pi-label .c-box.theme3 {
  background: var(--theme__color3);
}
.an-pi-label .c-box.theme1 {
  background: var(--theme__color1);
}
/* ---analytics-row---end */

/* ---s-card-row--- */
.s-card-row {
  --inner-space: var(--inner-gap);
  --bt-space: var(--inner-space);
  --item: 2;
  flex-wrap: wrap;
  margin: 0 calc(var(--inner-space) * -0.5) calc(var(--bt-space) * -1);
  justify-content: center;
  margin-bottom: 1px;
  display: flex;
}
.s-card-linear-row {
  --item: 1;
}
.s-card {
  width: calc((100% / var(--item)) - var(--inner-space));
  margin: 0 calc(var(--inner-space) * 0.5) var(--bt-space);
  background: #fff;
  border-radius: 5px;
}
.s-card-header {
  padding: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.s-card-header h4 {
  font-size: 19px;
  font-weight: 600;
  color: var(--theme__color2);
}
.s-card-header .a-btn {
  font-size: 14.3px;
  font-weight: 500;
  color: var(--theme__color2);
}
.s-card-header .a-btn i {
  margin-left: 4px;
}
.s-card-in-content {
  padding: 26px;
}
.s-card-row1 {
  display: flex;
}
.s-card-row1 .image {
  width: 50%;
  margin: 0 25px 0 0;
  flex-shrink: 0;
}
.s-card-row1 .image img {
  width: 100%;
}
.s-card-row1 .text-side .h3 {
  font-size: 21px;
  margin: 0 0 15px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--theme__color2);
  word-break: break-word;
}
.location-text {
  font-size: 12.8px;
  font-weight: 500;
  color: var(--theme__color3);
  display: flex;
}
.location-text .im {
  margin: 0 6px 0 0;
}
.s-card-states-wrap {
  margin: 38px 0 0;
  display: flex;
  justify-content: space-between;
}
.sc-state-single {
  text-align: center;
}
.sc-state-single h4 {
  font-size: 19.41px;
  color: var(--theme__color1);
}
.sc-state-single p {
  font-size: 13.89px;
  font-weight: 400;
  color: #bcbcbc;
}
.s-card-fill-row {
  margin: 30px 0 0;
}
.s-card-fill-row h4 {
  font-size: 17px;
  margin: 0 0 20px;
  font-weight: 600;
  color: var(--theme__color2);
}
.s-fill-bar {
  height: 32px;
  border-radius: 2px;
  background: #eeeeee;
}
.s-fill-bar .filled {
  height: 100%;
  background: var(--theme__color2);
  display: flex;
  border-radius: inherit;
}
.s-card .bt-button-row {
  margin-top: 20px;
  padding-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-card-row-full {
  display: flex;
  align-items: start;
}
.s-card-row-full .text-side {
  flex-grow: 1;
}
.s-card-row-full .text-side h3 {
  font-size: 34px;
  margin-bottom: 20px;
  padding-right: 15%;
  line-height: 1.35;
  font-weight: 600;
  color: var(--theme__color2);
}
.s-card-row-full .s-card-states-wrap {
  padding-right: 15%;
}
.s-card-row-full .text-side .bt-p {
  margin: 25px 0 0;
  font-size: 15px;
}
.s-card-row-full .image-side {
  width: 35.1%;
  margin-left: 7%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.s-card-row-full .image-side .img-m {
  width: 100%;
}
.s-card-row-full .image-side .img-m img {
  width: 100%;
  border-radius: 10px;
}
.s-card-row-full .image-side .bt-button {
  margin-top: 28px;
}
/* ---s-card-row---end */

/* ---table-box--- */
.table-box {
  background: #fff;
  border-radius: 5px;
}
.table-box-title {
  padding: 22px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.table-box-title h4 {
  font-size: 20px;
  font-weight: 500;
  color: var(--theme__color2);
}
.t-search .input {
  width: 261px;
  padding: 8px 15px;
  font-size: 12px;
  font-weight: 400;
  color: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background: transparent;
}
.t-search .input.input-button {
  width: 36px;
  height: 36px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.tbl-i-menu > ul {
  display: flex;
}
.tbl-i-menu > ul > li {
  margin-right: 9px;
}
.tbl-i-menu > ul > li:last-child {
  margin-right: 0;
}
.tbl-i-menu > ul > li > a {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  background: var(--theme__color2);
}
.table-box-footer .row-dr {
  padding: 22px 27px;
  display: flex;
}
.table-box-footer .row-dr .a-btn {
  font-size: 15.5px;
  font-weight: 500;
  color: #adadad;
  transition: 0.3s;
}
.table-box-footer .row-dr .a-btn:hover {
  color: var(--theme__color2);
}

/* table-model */
.table-model {
  --side-padding: 24px;
  overflow: auto;
}
.table-model td {
  font-size: 17px;
  padding: 22px 10px;
  font-weight: 400;
  color: #4d505f;
}
.table-model td:first-child {
  padding-left: var(--side-padding);
}
.table-model td:last-child {
  padding-right: var(--side-padding);
}
.table-model tbody tr:nth-child(even) td {
  background: #f8f8f8;
}
.table-model thead td {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme__color2);
  border-bottom: 1px solid #e9e9e9;
}
.t-str2-label {
  display: flex;
  align-items: center;
}
.t-str2-label .icon {
  width: 20px;
  margin: 0 10px 0 0;
  display: block;
  flex-shrink: 0;
}
.t-str2-label .txt {
  color: var(--theme__color1);
  font-weight: 500;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.d-in1-line {
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.table-model.table-model-bordered tbody tr:nth-child(2n) td {
  background: #fcfcfc;
}
.table-model.table-model-bordered td {
  border-bottom: 1px solid #e9e9e9;
}

/* table-mdl-str2 */
.table-mdl-str2 table {
  table-layout: unset;
}
.table-mdl-str2 td:nth-child(1) {
  width: 40px;
  padding-right: 0;
}
.table-mdl-str2 td:nth-child(2) {
  width: 15%;
}
.table-mdl-str2 td:nth-child(3) {
  width: 28%;
}
.table-mdl-str2 td:nth-child(5) {
  width: 45px;
}
.table-mdl-str2 .input-outer {
  display: flex;
  align-items: center;
}
.td-bookmark svg path {
  transition: 0.3s;
}
.td-bookmark.active svg path {
  fill: #fec64f;
  stroke: #fec64f;
}
/* table-mdl-str2-end */

/* table-mdl-str3 */
.table-mdl-str3 table {
  table-layout: unset;
}
.table-mdl-str3 td {
  padding-top: 13px;
  padding-bottom: 13px;
}
.table-mdl-str3 td:nth-child(3) {
  text-align: center;
}
.table-mdl-str3 td:nth-child(5) {
  width: 27%;
}
.table-mdl-str3 td:nth-child(6) {
  width: 24%;
}
.table-mdl-str3 td:nth-child(7) {
  width: 45px;
}
.td-cheap {
  font-size: 14px;
  padding: 8px 9px;
  min-width: 77px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--theme__color1);
  background: rgba(232, 62, 141, 0.1);
  display: inline-block;
  text-align: center;
}
.table-mdl-str3 .in-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-mdl-str3 .in-arrow img {
  width: 22px;
}
.th-dropdown > a {
  display: flex;
  align-items: center;
}
.th-dropdown > a .chev-down {
  font-size: 13px;
  margin: 0 0 0 8px;
  transition: 0.3s;
}
.th-dropdown > a.show .chev-down {
  transform: rotate(180deg);
}
/* table-mdl-str3-end */

/* ---table-box---end */

/* ---dropdown--- */
.dropdown-menu {
  padding: 0;
  border: none;
  background: #ffffff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}
.dropdown-menu-in-main {
  background: #ffffff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  z-index: 1;
}
.dropdown-overlay {
  background: #8d8d8d77;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.drop-select {
  text-align: center;
}
.drop-select > ul {
  padding: 0 10px;
}
.drop-select > ul > li > a {
  padding: 16px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #4d505f;
  transition: 0.3s;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  justify-content: center;
}
.drop-select > ul > li:last-child > a {
  border-bottom: none;
}
.drop-select > ul > li > a:hover {
  color: var(--theme__color1);
}
.drop-select > ul > li > a > .p-icon {
  width: 22px;
  margin: 0 8px 0 0;
}

/* notifications-dropdown */
.notifications-dropdown {
  width: 440px;
  padding-top: 17px;
  max-width: 100vw;
  background: transparent;
  box-shadow: none;
}
.notifications-dropdown-inner {
  background: #fff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}
.dropdown-title {
  padding: 22px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-title h4 {
  font-size: 14px;
  font-weight: 600;
  color: var(--theme__color2);
}
.all-r-button {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9d;
  cursor: pointer;
}
.notification-single {
  padding: 17px 25px;
  display: flex;
  border-bottom: 1px solid #e9e9e9;
}
.notification-single:last-child {
  border-bottom: none;
}
.notification-single .not-thumb {
  height: 32px;
  width: 32px;
  margin: 0 16px 0 0;
  border-radius: 4px;
  color: #fff;
  background: var(--theme__color1);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.notification-single .not-thumb img {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
}
.notification-single .not-thumb h4 {
  font-size: 12.31px;
  font-weight: 700;
  margin: 0;
}
.notification-single .not-thumb .dot {
  height: 8px;
  width: 8px;
  background: var(--theme__color2);
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-104%, -104%);
}
.notification-single .not-content h4 {
  font-size: 14px;
  font-weight: 500;
  color: #4d505f;
}
.notification-single .not-content h6 {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  color: #a5acb8;
}
/* notifications-dropdown-end */

.dropdown-sss {
  min-width: 117px;
  inset: 5px auto auto 0px !important;
}

/* ---dropdown---end */

/* ---mbl-table--- */
.mbl-table {
  --se-padding: 18px;
}
.mbl-table .str-col {
  padding-left: var(--se-padding) !important;
}
.mbl-table .end-col {
  padding-right: var(--se-padding) !important;
}
.mbl-table .tbl-tr {
  display: flex;
}
.mbl-table .tbl-hd {
  border-bottom: 1px solid #e9e9e9;
}
.mbl-table .tbl-hd .column {
  font-size: 13px;
  padding: 20px 10px;
  font-weight: 600;
  color: var(--theme__color2);
}
.mbl-table .tbl-bd .tbl-tr:nth-child(even) {
  background: #f8f8f8;
}
.mbl-table-str1 .tbl-hd .column:first-child {
  flex-grow: 1;
}
.mbl-table-str1 .tbl-hd .column:last-child {
  width: 115px;
  flex-shrink: 0;
  text-align: center;
}
.mbl-table-str1 .tbl-tr .row1 {
  padding: 14px 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.mbl-table-str1 .tbl-tr .row1 .left {
  flex-grow: 1;
}
.mbl-table-str1 .tbl-tr .row1 .right {
  flex-shrink: 0;
  width: 115px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mbl-table-str1 h4 {
  font-size: 15.26px;
  margin: 0 0 0;
  font-weight: 400;
  color: var(--theme__color2);
}
.mbl-table-str1 h5 {
  font-size: 14px;
  margin: 0 0 7px;
  font-weight: 400;
  color: var(--theme__color2);
  text-transform: uppercase;
}
.mbl-table-str1 .cont-d-b-i {
  display: flex;
  align-items: center;
}
.mbl-table-str1 .cont-d {
  font-size: 11.5px;
  font-weight: 400;
}
.mbl-table-str1 .cont-d-b-i .ellipse-one {
  display: inline-block;
  height: 4px;
  width: 4px;
  background: var(--theme__color2);
  border-radius: 50%;
  margin: 0 7px;
}
.mbl-table-str1 .cont-b {
  font-size: 11.5px;
  padding: 6px 9px;
  border-radius: 4px;
  color: var(--theme__color1);
  background: rgba(232, 62, 141, 0.1);
}
.mbl-table-str1 .cont-i {
  width: 22px;
  margin-left: 12px;
}
.mbl-table-str2 .tbl-hd .column:first-child {
  flex-grow: 1;
}
.mbl-table-str2 .tbl-hd .column:last-child {
  width: 115px;
  flex-shrink: 0;
  text-align: center;
}
.mbl-table-str2 .tbl-tr {
  flex-wrap: wrap;
}
.mbl-table-str2 .tbl-tr .row1 {
  padding: 23px 0 15px;
  display: flex;
  align-items: center;
  width: 100%;
}
.mbl-table-str2 .tbl-tr .row1 .left {
  padding-right: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.mbl-table-str2 .tbl-tr .row1 .left .checkbox {
  margin-right: 8px;
}
.mbl-table-str2 .tbl-tr .row1 .right {
  display: flex;
  align-items: center;
  text-align: center;
}
.mbl-table-str2 .tbl-tr .row1 .right .t-str2-label {
  font-size: 13px;
  margin: 0 13px 0 0;
  text-align: left;
}
.mbl-table-str2 h5 {
  font-size: 13px;
  margin: 0 0 0px;
  font-weight: 400;
  color: var(--theme__color2);
}
.mbl-table-str2 .tbl-tr .row2 {
  padding: 0 41px 23px;
}
.mbl-table-str2 .tbl-tr .row2 p {
  font-size: 14px;
  color: #4d505f;
  line-height: 1.5;
}
/* ---mbl-table---end */

/* ---pagination--- */
.pagination {
  margin-top: 20px;
  padding: 25px 24px;
  font-size: 17px;
  color: #adadad;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagi-nav {
  display: flex;
}
.pagi-nav li {
  margin-right: 3px;
}
.pagi-nav li:last-child {
  margin-right: 0;
}
.pagi-nav li a {
  padding: 5px 10px;
  transition: 0.3s;
  border: 1px solid transparent;
  display: flex;
}
.pagi-nav li a.active,
.pagi-nav li a:hover {
  border-color: var(--theme__color1);
  color: var(--theme__color1);
}
/* ---pagination---end */

/* ---account-box--- */
.account-box {
  padding: 23px 23px 45px;
  background: #fff;
  border-radius: 5px;
}
.account-c-group {
  margin: 0 0 50px;
}

.acc-grp-title {
  padding: 10px 0 25px;
  margin: 0 0 32px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
}
.acc-grp-title .f-icon {
  display: none;
}
.acc-grp-title h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 0;
  color: var(--theme__color2);
}
.acc-grp-title .ind-icon {
  display: none;
}
@media (min-width: 768px) {
  .acc-grp-m-content-inner {
    display: block !important;
    height: initial !important;
  }
}
.acc-grp-form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 120px;
  grid-row-gap: 25px;
}
.acc-grp-form-row .column-dbl-row {
  grid-row: span 2;
}
.acc-grp-form-row .column-dbl-column {
  grid-column: span 2;
}

.acc-user-img {
  height: 198px;
  width: 170px;
}
.acc-user-img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.pan-card-dyn {
  height: 226px;
  width: 449px;
  padding: 10px 20px;
  font-size: 13px;
  color: #000;
  font-weight: 500;
  font-family: var(--roboto);
  display: flex;
  align-items: flex-end;
}
.pan-card-dyn .color-purple {
  color: purple;
}
code {
  font-size: inherit;
  color: inherit;
  word-wrap: inherit;
}
/* ---account-box---end */

/* ---input-x--- */
.single-input-x {
  width: 100%;
}
.input-x-outer {
  width: 100%;
  position: relative;
}
.input-x-outer .i-check {
  font-size: 18px;
  right: 15px;
  color: #4cbc9a;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.single-input-x-valid .input-x {
  border-color: #4cbc9a;
  color: #4cbc9a;
}
.input-x {
  font-size: 13px;
  padding: 15px 22px;
  border-radius: 2px;
  font-weight: 400;
  color: #b1b1b1;
  /* border: 1px solid #f4f4f4; */
  background: #fdfdfd;
  width: 100%;
  line-height: 1.5;
  resize: none;
}
.input-x.input-x-big {
  height: 193px;
}
/* input:invalid {
  background-color: ivory;
  border: none;
  outline: 2px solid red;
  border-radius: 5px;
} */
.input-x.nice-select {
  line-height: 1.5;
  display: flex;
}
.input-x.input-file {
  text-align: center;
  border: 2px dashed #f4f4f4;
}
.label-x {
  font-size: 15px;
  margin: 0 0 9px;
  font-weight: 400;
  color: #959595;
  width: 100%;
}
/* ---input-x---end */
/* input[type='text' i] {
	padding: 10px 30px !important;
	padding-top: 1px;
	padding-right: 2px;
	padding-bottom: 1px;
	padding-left: 2px;
} */
@media (min-width: 425px) {
  .otp-input {
    /* @media (min-width: 600px) { */
    width: 84px !important;
    height: 53px !important;
    /* } */
  }
}
@media (max-width: 424px) {
  .otp-input {
    /* @media (min-width: 600px) { */
    width: 54px !important;
    height: 43px !important;
    /* } */
  }
}
/* .invalid {
  border: 1px solid #ff0000 !important;
} */
/* input:invalid {
  border: 2px solid pink;
} */
